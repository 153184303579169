.Link:hover {
  text-decoration:  none ;
}

a {
    color: #004982;
    text-decoration: underline;
}

.styledContainer{
  background-color: #F9F9F9;
  border-radius: 6px;
  padding: 20px;
  max-width: 800px;
}

.centered{
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.form-group{
  width: 100%;
}

.form-control{
  width: 100%;
  border-color: #ECECEC;
  background-color: #ECECEC;
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 300;
}

.btn-primary{
    width: 100%;
  border: none;
  background-color: #004982;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 20px;
  opacity: 1;
  max-width: 280px;
    text-decoration: none;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle
{
    background-color: #004982;
    color: #FFFFFF;
    cursor: default;
    pointer-events: all !important;
}

.btn-disabled{
  opacity: .5;
  transition:opacity 1s;
}

.text-secondary{
  font-size: 0.9375rem;
    color: #000000 !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
  background-position: right calc(.375em + 0.8rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.card-heading{
  color: #264572;
  font-size: 1.125rem;
  margin-bottom:20px
}

p.value{
  font-size: 1.125rem;
  color: #000;
  margin-bottom: 0;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 2rem;
    pointer-events: all;
    border-radius: .6rem;
    height: 1.2rem;
    background-color: #E8ECF1 ;
    border-color: #E8ECF1;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1.2rem - 4px);
    height: calc(1.2rem - 4px);
    background-color: #E8ECF1;
    border-radius: .6rem;
    border: 1px solid #ADBBCD;
    background-image: none;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #1A497E;
    background-color: #1A497E;
    left: -2.25rem;
    width: 2rem;
    pointer-events: all;
    border-radius: .6rem;
    height: 1.2rem;
}

.custom-control-input:checked~.custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 3px);
    width: calc(1.2rem - 4px);
    height: calc(1.2rem - 4px);
    background-image: url(../../assets/check-on.svg);
    background-repeat: no-repeat;
    background-size:80% auto;
    border: 1px solid #fff;
}

.price{
  color: #004982;
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 10px;
}

.price-term{
  color: #004982;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-top: -6px;
}

.modal-header, .modal-footer{
  flex-grow: 1;
  border-bottom: none;
  border-top: none;
}

.modalTitle, .modalFooter{
  width: 100% ;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
}

.address-wrapper{
  background-color:transparent;
  border-radius: 6px;
  padding: 20px;
  flex-basis: 0;
  font-size: 1.5rem;
  text-align: center;
  color: #000;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-grow: 1;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.05);
}

.btn-edit{
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #004982;
  font-weight: 600;
}

@-webkit-keyframes gradientBG {
 0% {
 background-position: 0 50%;
 }
 50% {
 background-position: 100% 50%;
 }
 100% {
 background-position: 0 50%;
 }
}

@keyframes gradientBG {
 0% {
 background-position: 0 50%;
 }
 50% {
 background-position: 100% 50%;
 }
 100% {
 background-position: 0 50%;
 }
}

.termsFooter {
  font-size: 0.75em;
  border-top: 1px solid #999;
  padding-top: 10px;
}

.error {
  color: red;
  font-size: 0.9em;
}

.ReactModal__Overlay {
  background-color: #3e3e3eb8 !important;
}
.ReactModal__Content {
  position: absolute;
  inset: 40px;
  border: 1px solid #000;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 700px;
  max-width: 80%;
  margin: 0 auto;
}
.buton-as-link {
  background: transparent;
  border: 0;
  padding: 0;
  display: inline;
  color: #004982;
}
.quote-modal-link {
  color: #004982;
  text-decoration: underline;
  font-size: 12px;
}

.disclaimer {
  max-width: 800px;
  margin: 0 auto;
  padding: 0px 20px 20px 20px;
  font-size: 10px;
  opacity: 0.5;
  text-align: justify;
}

.hidden {
  display: none;
}